import dynamic from 'next/dynamic'
import type { FC, SyntheticEvent } from 'react'

import { useGetParagraphQuery } from '@redux/features/homePage/api'
import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import useToggle from '@hooks/useToggle/useToggle'
import ArrowDownIcon from '@iconscout/unicons/svg/line/arrow-down.svg'

import { Cation, Container, Description, StyledLink, Wrapper } from './styles'

const MobileAppModal = dynamic(() => import('@components/modals/MobileApp'))

type Props = {
  itemId: number
}

export const ListItem: FC<Props> = ({ itemId }) => {
  const { on: open, toggleOff, toggleOn } = useToggle(false)
  const { data } = useGetParagraphQuery(itemId)
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  const onCardClick = (e: SyntheticEvent) => {
    e.preventDefault()

    if (data?.action === 'popup') {
      toggleOn()
      return
    }
    window.open(data?.link?.uri || '/')
  }

  return (
    <>
      <Container>
        <StyledLink href="/" onClick={onCardClick}>
          <Wrapper>
            <Cation size="xsmall-strong">{data?.title}</Cation>
            <ArrowDownIcon />
          </Wrapper>
          <Description dangerouslySetInnerHTML={{ __html: data?.description || '' }} size="small-strong" />
        </StyledLink>
      </Container>
      {isPageInteraction && data?.action === 'popup' && data?.popup_block && (
        <MobileAppModal blockId={data.popup_block} onClose={toggleOff} open={open} />
      )}
    </>
  )
}
